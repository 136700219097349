import React from 'react'
import { Box, List, ListItem, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../../theme';
import { RootState } from '../../../../app/reducers/v2/rootReducer';

const useStyle = makeStyles((theme: any) => ({
    customPopper: {
        zIndex: 1024,
    },
    arrow: {
        color: `${theme.palette.common.white} !important`,
    },
    feesCustomWidth: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        color: theme.palette.common.black50,
        boxShadow: '0px 10px 25px 10px rgba(136, 136, 136, 1)',
    },
}));

const OutstandingTreatment = () => {
    const classes = useStyle();

    const {
        plannedTreatmentReducer: { treatmentFeesData },
    } = useSelector((state: RootState) => state);

    const totalFeeAmount = treatmentFeesData?.reduce((total: any, item: any) => {
        return total + item?.fee_amount;
    }, 0);

    return (
        <Tooltip
            arrow
            classes={{
                popper: classes.customPopper,
                tooltip: classes.feesCustomWidth,
                arrow: classes.arrow
            }}
            title={
                treatmentFeesData?.length ?
                    <Box>
                        <Typography
                            p={1}
                            className={`labelColor fw-medium f-14 lh-19 d-flex-all-center`}
                            borderBottom={`1px solid ${theme.palette.divider}`}
                        >
                            {`Outstanding treatment value: $${totalFeeAmount?.toFixed(2)}`}
                        </Typography>

                        <List sx={{ listStyle: "circle", pl: 2 }}>
                            {treatmentFeesData?.map((item: any, index: number) => (
                                <ListItem
                                    key={index}
                                    className={`labelColor fw-regular f-13 lh-19`}
                                    sx={{
                                        display: "list-item",
                                        listStyleType: "circle",
                                        padding: '2px'
                                    }}
                                >
                                    {`${item?.description} ($${item?.fee_amount?.toFixed(2)})`}
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    :
                    <Typography
                        p={1}
                        className={`labelColor fw-medium f-14 lh-19 d-flex-all-center`}
                    >
                        Outstanding treatment could not be found for this patient
                    </Typography>
            }
        >
            <Box display={'flex'} gap={1} mr={2.5} alignItems={'center'} className='cursor-pointer'>
                <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    color={theme.palette.v2.primary.main}
                    fontSize={30}
                />
            </Box>
        </Tooltip>
    )
}

export default OutstandingTreatment