import React from 'react'
import PaperTable from '@mui/material/Paper'
import {
    Box,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faNoteSticky} from '@fortawesome/free-solid-svg-icons'
import {makeStyles} from '@mui/styles'
import theme from "../../../../../../../theme";
import CollapsibleBox from "../../../../../common/CollapsibleBox";
import InputHeading from "../../../../../common/InputHeading";
import DropDown from "../../../../../common/DropDown";
import {
    appointmentTime,
    insuranceStatusDropdown,
    treatmentStatusDropdown
} from "../../../../../../../utils/v2/newAdminHandoffConstant";
import {columnInterface, MenuProps} from "../../../../../../../utils/v2/appointmentConstant";
import {treatmentDescription} from "../../../../../../../utils/helper";
import TextBox from "../../../../../common/TextBox";
import {
    pastTreatmentColumns
} from "../../../../../../../utils/v2/wellnessProfileConstant";
import ALIcon from "../../../../../../../assets/images/v2/alternativeIcon.svg";


const useStyles = makeStyles((theme: any) => ({
    table: {
        borderCollapse: 'separate',
        boxShadow: 'none',
        borderSpacing: '0px 2px!important',
        '& tr:first-child': {
            borderSpacing: '0px 10px!important',
        },
    },
    th: {
        padding: '17px 16px',
        fontSize: '13px',
        lineHeight: '18px',
        color: theme.palette.common.black50,
        borderBottom: 'none',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        fontFamily: "FavoritPro-Medium"
    },
    headROwBorder: {
        borderLeft: `1px dashed ${theme.palette.common.white}`,
        color: theme.palette.common.black50,
        paddingLeft: '20px',
    },
    tr: {
        height: '66px',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    td: {
        borderBottom: 'none',
        height: `40px`,
        fontFamily: "FavoritPro-Regular",
        lineHeight: '22px',
        color: theme.palette.common.black50,
    },
    bodyRowBorder: {
        fontSize: '14px',
        color: theme.palette.common.black50,
    },
    scheduleAppointment: (disabledField: any) => ({
        height: '40px',
        borderRadius: '8px',
        "& .MuiInputBase-root": {
            height: '40px',
            borderRadius: '8px',
        },
        "& .MuiOutlinedInput-input": {
            fontFamily: "FavoritPro-Regular !important",
        },
        "& fieldset": {
            borderColor: `transparent`,
        },
        "&:hover fieldset": {
            borderColor: disabledField ? `none !important` : 'transparent !important', // change hover color based on props
        },
        "&.Mui-focused fieldset": {
            border: `2px solid transparent !important`,
        },
        "& .Mui-disabled": {
            "& .MuiOutlinedInput-input": {
                fontFamily: "FavoritPro-Regular !important",
                "& fieldset": {
                    border: `1px solid transparent !important`,
                },
            },
        }
    }),
    noteClass: {
        marginLeft: '8px !important',
        width: '15px',
        height: '15px'
    },
    tooltip: {
        fontSize: "12px",
        fontFamily: "FavoritPro-Regular"
    },
}))

type Props = {
    treatmentData: any
}
const WellnessPastTreatmentList = (props: Props) => {
    const {treatmentData} = props
    const providerNameList = [] as any
    const {palette} = theme
    const classes = useStyles(true);

    return (
        <React.Fragment>  {treatmentData?.map((res: any, index: number) =>
            <CollapsibleBox title={`Appointment ${index + 1}`} key={index} id={`${index}`}>
                <Grid container rowSpacing={1.5} columnSpacing={2.5}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <InputHeading title={'Appointment Time (In Units)'}/>
                        <DropDown
                            selectOptions={appointmentTime}
                            inputBackColor={theme?.palette?.common?.white}
                            disabled
                            selectProps={{
                                name: 'time',
                                value: res?.time || '',
                                // onChange: (e: any) => handleChangeInsurance(e, index),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <InputHeading title={'Provider'}/>
                        <DropDown
                            sx={{textTransform: 'capitalize'}}
                            selectOptions={providerNameList}
                            inputBackColor={theme?.palette?.common?.white}
                            disabled
                            selectProps={{
                                name: 'provider_id',
                                value: res?.provider,
                                // onChange: (e: any) => handleChangeInsurance(e, index),
                            }}
                        />
                    </Grid>

                    <Grid className={`d-flex-all-center text-align-center`} item xs={12} pl={'20px'}
                          sx={{marginTop: '20px'}}>
                        <TableContainer component={PaperTable} sx={{backgroundColor: palette.common.white}}>
                            <Table style={{borderCollapse: 'collapse',}} aria-label='simple table'
                                   classes={{root: classes.table}}>
                                <TableHead sx={{
                                    backgroundColor: palette.primary.greenLight,
                                    borderBottom: `1px solid ${palette.v2.primary.main}`
                                }}>
                                    <TableRow sx={{maxWidth: '100%'}}>
                                        {pastTreatmentColumns?.map((column: columnInterface, i: number) => (
                                            <TableCell
                                                key={i}
                                                classes={{root: classes.th}}
                                                align={column?.textAlign}
                                                sx={{minWidth: column?.minWidth, maxWidth: column?.maxWidth}}
                                            >
                                                {column?.label || '-'}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{backgroundColor: palette.common.white}}>
                                    {res?.treatments?.map((row: any, i: number) => (
                                        <React.Fragment key={i}>
                                            <TableRow classes={{root: classes.tr}}>
                                                <TableCell classes={{root: classes.td}} align='left'
                                                           sx={{verticalAlign: 'top'}}>
                                                    {i + 1 || 0}
                                                </TableCell>
                                                {/*description*/}
                                                <TableCell classes={{root: classes.td}} align='left'>
                                                    <Box display={'flex'} alignItems={'flex-start'}
                                                         justifyContent={'flex-start'} height={'100%'}
                                                         flexDirection={'column'}>
                                                        <Box display={'flex'} flexDirection={'column'}>
                                                            <Typography display={'flex'} textTransform={'capitalize'}
                                                                        className='f-14 fw-medium labelColor'>
                                                                {treatmentDescription(row?.treatment_finding, true)}
                                                            </Typography>
                                                            {row?.treatment_type === 'other' ?
                                                                <Typography textTransform={'capitalize'} key={index} display={'flex'}
                                                                            className={`f-14 fw-regular labelColor`}>
                                                                    {row?.procedure_text}
                                                                </Typography>
                                                                : row?.treatment_type === 'additional' ?
                                                                    <Typography textTransform={'capitalize'} key={index} display={'flex'}
                                                                                className={`'f-14'} fw-regular labelColor`}>
                                                                        {row?.treatment_procedure[0]?.description}
                                                                        {row?.treatment_procedure[0]?.notes &&
                                                                            <Tooltip
                                                                                title={row?.treatment_procedure[0]?.notes}
                                                                                arrow
                                                                                classes={{tooltip: 'fw-regular f-14 lh-18'}}>
                                                                                <FontAwesomeIcon icon={faNoteSticky}
                                                                                                 color={palette.v2.primary.main}
                                                                                                 className={`${classes.noteClass} cursor-pointer f-14`}/>
                                                                            </Tooltip>
                                                                        }
                                                                    </Typography>
                                                                    :
                                                                    row?.treatment_procedure && row?.treatment_procedure?.map((res: any, index: number) =>
                                                                        <Typography textTransform={'capitalize'} key={index} display={'flex'}
                                                                                    className={`${row?.alternative_finding ? 'f-12 ' : 'f-14'} fw-regular labelColor`}>
                                                                            {res?.quantity === null && res?.description}
                                                                        </Typography>
                                                                    )}
                                                        </Box>
                                                    </Box>
                                                </TableCell>

                                                {/*code*/}
                                                <TableCell classes={{root: classes.td}} align='left'
                                                           sx={{maxWidth: '235px'}}>
                                                    <Box display={'flex'} height={'100%'}>
                                                        <Typography display={'flex'} flexDirection={'column'}
                                                                    height={'100%'}>
                                                            {row?.treatment_type === 'other' ? '-' : row?.treatment_procedure?.map((res: any, index: number) => (
                                                                <Typography key={index} className='fw-regular'
                                                                            color={palette.common.black50}
                                                                            height={'100%'}>
                                                                    {res?.code}
                                                                    {res?.quantity ? ` x (${res?.quantity})` : null}
                                                                </Typography>
                                                            ))
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </TableCell>

                                                {/*TODO: if we show fees*/}
                                                {/*Procedure Fees*/}
                                                {/*<TableCell classes={{root: classes.td}} align='left'>*/}
                                                {/*    <Typography display={'flex'} flexDirection={'column'}*/}
                                                {/*                height={'100%'}>*/}
                                                {/*        {row?.treatment_procedure?.map((procRes: any, procIndex: number) => (*/}
                                                {/*            <FeesCountData*/}
                                                {/*                key={procIndex}*/}
                                                {/*                rowData={procRes}*/}
                                                {/*                maxLength={10}*/}
                                                {/*                inputProps={{*/}
                                                {/*                    // InputProps: {*/}
                                                {/*                    //   inputComponent: IMaskInput,*/}
                                                {/*                    //   inputProps: {*/}
                                                {/*                    //     ...oneMaskDefinitions,*/}
                                                {/*                    //   }*/}
                                                {/*                    // },*/}
                                                {/*                    // onWheel: (e: any) => {*/}
                                                {/*                    //   e.target.blur()*/}
                                                {/*                    // },*/}
                                                {/*                    disabled: true,*/}
                                                {/*                    id: 'fees',*/}
                                                {/*                    name: 'fees',*/}
                                                {/*                    // onKeyDown: (e: any) => exceptThisSymbols.includes(e.key) && e.preventDefault(),*/}
                                                {/*                    onChange: (e: any) => {*/}
                                                {/*                        // handleChangeFeesInsurance(e, index, i, procIndex)*/}
                                                {/*                    },*/}
                                                {/*                    value: (procRes?.fees),*/}
                                                {/*                    // error: errorHandling(index, i, procIndex).length > 0,*/}
                                                {/*                    // helperText: errorHandling(index, i, procIndex),*/}
                                                {/*                    fullWidth: true,*/}
                                                {/*                }}*/}
                                                {/*            />*/}
                                                {/*        ))}*/}
                                                {/*    </Typography>*/}
                                                {/*</TableCell>*/}

                                                {/*TODO: if we show fees*/}
                                                {/*total fees*/}
                                                {/*<TableCell classes={{root: classes.td}} align='left'>*/}
                                                {/*    <Typography display={'flex'} flexDirection={'column'}*/}
                                                {/*                height={'100%'}>*/}
                                                {/*        {row?.treatment_procedure?.map((procRes: any, procIndex: number) => (*/}
                                                {/*            <Typography key={procIndex}*/}
                                                {/*                        className={`fw-regular labelColor d-flex align-center`}*/}
                                                {/*                        align='left'>*/}
                                                {/*                {countTotalFeesPastAppointment(procRes?.fees, procRes?.quantity)}*/}
                                                {/*            </Typography>*/}
                                                {/*        ))}*/}
                                                {/*    </Typography>*/}
                                                {/*</TableCell>*/}

                                                {/*Insurance Status*/}
                                                {row?.alternative_procedure === null &&
                                                    <TableCell classes={{root: classes.td}} align='left'
                                                               sx={{verticalAlign: 'top'}}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                value={row?.insurance_status ? row?.insurance_status : ''}
                                                                displayEmpty
                                                                inputProps={{'aria-label': 'Without label'}}
                                                                name='insurance_status'
                                                                // onChange={(e) => handleStatusChange(e, index, i)}
                                                                className={`${classes.scheduleAppointment} border-radius-8`}
                                                                MenuProps={MenuProps}
                                                                disabled
                                                                sx={{
                                                                    borderRadius: '25px !important',
                                                                    backgroundColor: (row?.insurance_status === 'Send Preauth' || row?.insurance_status === 'Preauth Sent') ?
                                                                        `${palette.primary.main}` :
                                                                        (row?.insurance_status === 'Waiting for Insurance Approval') ? `${palette.warning.main}` :
                                                                            (row?.insurance_status === 'Insurance Approved Tx') ? `${palette.success.main} ` :
                                                                                (row?.insurance_status === 'Insurance Denied Tx') ? `${palette.error.main}` : `${palette.common.white}`,
                                                                    color: (!row?.insurance_status) ? `${palette.common.black50}` : `${palette.common.white}`,
                                                                    '.MuiSvgIcon-root': {
                                                                        color: theme.palette.text.secondary,
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem className={`labelColor fw-regular`}
                                                                          value={''}>Select</MenuItem>
                                                                {insuranceStatusDropdown && insuranceStatusDropdown?.map((res: any, index: number) =>
                                                                    <MenuItem key={index}
                                                                              className={`labelColor fw-regular`}
                                                                              value={res?.value}>
                                                                        {res?.label}
                                                                    </MenuItem>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>}

                                                {/*Treatment Status*/}
                                                {row?.alternative_procedure === null &&
                                                    <TableCell classes={{root: classes.td}} align='left'
                                                               sx={{verticalAlign: 'top'}}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                value={row?.status ? row?.status : ''}
                                                                displayEmpty
                                                                inputProps={{'aria-label': 'Without label'}}
                                                                name='status'
                                                                // onChange={(e) => handleStatusChange(e, index, i)}
                                                                className={`${classes.scheduleAppointment} border-radius-8`}
                                                                MenuProps={MenuProps}
                                                                disabled
                                                                sx={{
                                                                    borderRadius: '25px !important',
                                                                    backgroundColor: (row?.status === 'Pending') ? `${palette.primary.main}` :
                                                                        (row?.status === 'Ongoing') ? `${palette.warning.main}` :
                                                                            (row?.status === 'Completed') ? `${palette.success.main}` :
                                                                                `${palette.common.white}`,
                                                                    color: (!row?.status) ? `${palette.common.black50}` : `${palette.common.white}`,
                                                                    '.MuiSvgIcon-root': {
                                                                        color: theme.palette.text.secondary
                                                                        ,
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem className={`labelColor fw-regular`}
                                                                          value={''}>Select</MenuItem>
                                                                {treatmentStatusDropdown && treatmentStatusDropdown?.map((res: any, index: number) =>
                                                                    <MenuItem key={index}
                                                                              className={`labelColor fw-regular`}
                                                                              value={res?.value}>
                                                                        {res?.label}
                                                                    </MenuItem>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>}

                                            </TableRow>

                                            {(row?.alternative_procedure || row?.alternative_treatment_type === 'other') ?
                                                <TableRow classes={{root: classes.tr}}>
                                                    <TableCell classes={{root: classes.td}} align='left'></TableCell>
                                                    {/*description*/}
                                                    <TableCell classes={{root: classes.td}} align='left'>
                                                        <Box display={'flex'} alignItems={'flex-start'}
                                                             justifyContent={'flex-start'} height={'100%'}
                                                             flexDirection={'column'}>
                                                            <Box display={'flex'}>
                                                                <img src={ALIcon} alt='alternative'/>
                                                                <Box ml={3} mt={'2px'} display={'flex'}
                                                                     flexDirection={'column'}>
                                                                    <Typography display={'flex'}
                                                                                className='f-14 fw-medium labelColor'>
                                                                        {treatmentDescription(row?.alternative_finding, true)}
                                                                    </Typography>
                                                                    {row?.alternative_treatment_type === 'other' ?
                                                                        <Typography display={'flex'}
                                                                                    className={`f-14 fw-regular labelColor`}>
                                                                            {row?.alternative_procedure_text}
                                                                        </Typography> :
                                                                        <Typography display={'flex'}
                                                                                    className={`${row?.alternative_finding ? 'f-12 ' : 'f-14'} fw-regular labelColor`}>
                                                                            {row?.alternative_procedure?.description}
                                                                        </Typography>}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </TableCell>


                                                    {/*code*/}
                                                    <TableCell classes={{root: classes.td}} align='left'
                                                               sx={{maxWidth: '235px'}}>
                                                        <Box display={'flex'} height={'100%'}>
                                                            <Typography className='fw-regular'
                                                                        color={palette.common.black50}>
                                                                {row?.alternative_treatment_type === 'other' ? "-" : row?.alternative_procedure?.code}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>

                                                    {/*TODO: if we show fees*/}
                                                    {/*Procedure Fees*/}
                                                    {/*<TableCell classes={{root: classes.td}} align='left'>*/}
                                                    {/*    <Typography display={'flex'} flexDirection={'column'}   height={'100%'}>*/}
                                                    {/*        <FeesCountData*/}
                                                    {/*            rowData={row?.alternative_procedure}*/}
                                                    {/*            maxLength={10}*/}
                                                    {/*            inputProps={{*/}
                                                    {/*                // InputProps: {*/}
                                                    {/*                //   inputComponent: IMaskInput,*/}
                                                    {/*                //   inputProps: {*/}
                                                    {/*                //     ...oneMaskDefinitions,*/}
                                                    {/*                //   }*/}
                                                    {/*                // },*/}
                                                    {/*                disabled: true,*/}
                                                    {/*                id: 'fees',*/}
                                                    {/*                name: 'fees',*/}
                                                    {/*                onWheel: (e: any) => {*/}
                                                    {/*                    e.target.blur()*/}
                                                    {/*                },*/}
                                                    {/*                // onKeyDown: (e: any) => exceptThisSymbols.includes(e.key) && e.preventDefault(),*/}
                                                    {/*                onChange: (e: any) => {*/}
                                                    {/*                    // handleChangeFeesInsurance(e, index, i)*/}
                                                    {/*                },*/}
                                                    {/*                value: (row?.alternative_procedure?.fees),*/}
                                                    {/*                // error: errorAlternativeHandling(index, i).length > 0,*/}
                                                    {/*                // helperText: errorAlternativeHandling(index, i),*/}
                                                    {/*                fullWidth: true,*/}
                                                    {/*            }}*/}
                                                    {/*        />*/}
                                                    {/*    </Typography>*/}
                                                    {/*</TableCell>*/}

                                                    {/*TODO: if we show fees*/}
                                                    {/*total fees*/}
                                                    {/*<TableCell classes={{root: classes.td}} align='left'>*/}
                                                    {/*    <Typography className={`${classes.td} d-flex align-center`}*/}
                                                    {/*                align='left'>*/}
                                                    {/*        {countTotalFeesPastAppointment(row?.alternative_procedure?.fees, row?.alternative_procedure?.quantity)}*/}
                                                    {/*    </Typography>*/}
                                                    {/*</TableCell>*/}

                                                    <TableCell classes={{root: classes.td}} align='left'
                                                               sx={{verticalAlign: 'top'}}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                value={row?.insurance_status ? row?.insurance_status : ''}
                                                                displayEmpty
                                                                inputProps={{'aria-label': 'Without label'}}
                                                                name='insurance_status'
                                                                // onChange={(e) => handleStatusChange(e, index, i)}
                                                                className={`${classes.scheduleAppointment} border-radius-8`}
                                                                MenuProps={MenuProps}
                                                                disabled
                                                                sx={{
                                                                    borderRadius: '25px !important',
                                                                    backgroundColor: (row?.insurance_status === 'Send Preauth' || row?.insurance_status === 'Preauth Sent') ?
                                                                        `${palette.primary.main}` :
                                                                        (row?.insurance_status === 'Waiting for Insurance Approval') ? `${palette.warning.main}` :
                                                                            (row?.insurance_status === 'Insurance Approved Tx') ? `${palette.success.main} ` :
                                                                                (row?.insurance_status === 'Insurance Denied Tx') ? `${palette.error.main}` : `${palette.common.white}`,
                                                                    color: (!row?.insurance_status) ? `${palette.common.black50}` : `${palette.common.white}`,
                                                                    '.MuiSvgIcon-root': {
                                                                        color: theme.palette.text.secondary,
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem className={`labelColor fw-regular`}
                                                                          value={''}>Select</MenuItem>
                                                                {insuranceStatusDropdown && insuranceStatusDropdown?.map((res: any, index: number) =>
                                                                    <MenuItem key={index}
                                                                              className={`labelColor fw-regular`}
                                                                              value={res?.value}>
                                                                        {res?.label}
                                                                    </MenuItem>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>

                                                    <TableCell classes={{root: classes.td}} align='left'
                                                               sx={{verticalAlign: 'top'}}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                value={row?.status ? row?.status : ''}
                                                                displayEmpty
                                                                inputProps={{'aria-label': 'Without label'}}
                                                                name='status'
                                                                // onChange={(e) => handleStatusChange(e, index, i)}
                                                                className={`${classes.scheduleAppointment} border-radius-8`}
                                                                MenuProps={MenuProps}
                                                                disabled
                                                                sx={{
                                                                    borderRadius: '25px !important',
                                                                    backgroundColor: (row?.status === 'Pending') ? `${palette.primary.main}` :
                                                                        (row?.status === 'Ongoing') ? `${palette.warning.main}` :
                                                                            (row?.status === 'Completed') ? `${palette.success.main}` :
                                                                                `${palette.common.white}`,
                                                                    color: (!row?.status) ? `${palette.common.black50}` : `${palette.common.white}`,
                                                                    '.MuiSvgIcon-root': {
                                                                        color: theme.palette.text.secondary
                                                                        ,
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem className={`labelColor fw-regular`}
                                                                          value={''}>Select</MenuItem>
                                                                {treatmentStatusDropdown && treatmentStatusDropdown?.map((res: any, index: number) =>
                                                                    <MenuItem key={index}
                                                                              className={`labelColor fw-regular`}
                                                                              value={res?.value}>
                                                                        {res?.label}
                                                                    </MenuItem>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>

                                                </TableRow> : ''}
                                        </React.Fragment>
                                    ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <TextBox
                            label={'Notes'}
                            labelProps={{
                                htmlFor: 'notes'
                            }}
                            inputBackColor={theme?.palette?.common?.white}
                            disabled
                            inputProps={{
                                id: 'notes',
                                name: 'notes',
                                // onChange: (e: any) => handleChangeInsurance(e, index),
                                value: res?.notes || '',
                                fullWidth: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </CollapsibleBox>
        )}
        </React.Fragment>
    )
}

export default WellnessPastTreatmentList