import {debounce} from "lodash";
import moment from "moment";
import {toast} from "react-toastify";
import {v4 as uuidv4} from "uuid";
import {keyframes} from "@mui/material";
import validator from 'validator';
import theme from "../theme";
import {getShortSurface} from './v2/clinicalExamConstant';
import {findingData} from "./v2/examConstant";
import pusher from "./pusher";

export const getUniqId = () => uuidv4();
export const currentYear = moment().year();
export const url =
    process.env.REACT_APP_API_ENDPOINT ||
    "https://dev-oraq-apis.35.239.156.91.nip.io";

export const formatPhoneNumber = (phoneNumberString: any) => {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return cleaned;
};
export const getLocalStorageItem = (key: any) => localStorage.getItem(key);
const patientClinicInfo = localStorage.getItem('patientClinicInfo')
const parseClinicInfoData = patientClinicInfo ? JSON.parse(patientClinicInfo || '') : {};
export const patientProfileData = parseClinicInfoData && parseClinicInfoData?.profile

const dentistClinicInfo = localStorage.getItem('clinicInfo')
const parseDentistClinicInfoData = dentistClinicInfo ? JSON.parse(dentistClinicInfo || '') : {};
export const dentistProfileData = parseDentistClinicInfoData && parseDentistClinicInfoData?.profile

export const phoneNumberPlaceholder = "(XXX)-XXX-XXXX";
export const postalCodePlaceholder = "XXXXXX";
export const canadaPostalRegex = new RegExp(/[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d/);
export const usPostalRegex = new RegExp(/^\d{5}(?:-\d{4})?$/);
export const emailRegex = /^([A-Za-z]+[A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const NUMBER_REGEX = /^[0-9 ]*$/i;
export const accessCodeRegex = /^[0-9-]*$/;

export function AvoidSpace(e: any) {
    if (e.target.value.length === 0 && e.which === 32) {
        e.preventDefault();
    }
}

export function AvoidSpaceForPassword(e: any) {
    if (e.which === 32) {
        e.target.value.trim();
        e.preventDefault();
    }
}

export const convertToLowerCase = (string: string) => {
    let newValue = "";
    if (string) {
        newValue = string?.toLowerCase();
    }
    return newValue;
};

export const selectedClinic = getLocalStorageItem("clinic");

export const stringToArray = (str: string) => {
    let arr = str?.split(",")?.filter((f: string) => f?.length);
    return arr;
};

export const splitData = (str: string) => {
    let arr = str?.split(",");
    return arr;
};

export const arrayToString = (arr: any) => {
    let str = arr?.toString();
    return str;
};

export const removeRefFromJson = (data: any) => {
    return JSON.parse(JSON.stringify(data)) as any;
}

export const usDateFormat = "MM/DD/YYYY" as string;

export const newUsDateFormatInput = "MM-DD-YYYY";
export const newUsDateFormat = "YYYY-MM-DD";

export const dateFormat = (dateValue: any) => {
    if (dateValue) {
        return moment(dateValue).format(usDateFormat);
    }
    return "";
};

export const newDateFormate = (dateValue: any) => {
    if (dateValue) {
        return moment(new Date(dateValue))?.format(newUsDateFormat);
    }
    return "";
};

export const resourceDateFormate = (dateValue: any) => {
    if (dateValue) {
        return moment(new Date(dateValue))?.format(newUsDateFormatInput);
    }
    return "";
};

export const newDateFormateNotificationAgo = (dateValue: any) => {
    if (dateValue) {
        return moment(dateValue).fromNow();
    }
    return "";
};

export const newDateFormateNotification = (dateValue: any) => {
    if (dateValue) {
        return moment(dateValue).format("MM-DD-YYYY, LT");
    }
    return "";
};

export const hygieneType = ['hygiene', 'exam', 'adjunctive', 'xray'] as any

export const marks10 = [
    {
        value: 0,
        label: "00",
    },
    {
        value: 1,
        label: "01",
    },
    {
        value: 2,
        label: "02",
    },
    {
        value: 3,
        label: "03",
    },
    {
        value: 4,
        label: "04",
    },
    {
        value: 5,
        label: "05",
    },
    {
        value: 6,
        label: "06",
    },
    {
        value: 7,
        label: "07",
    },
    {
        value: 8,
        label: "08",
    },
    {
        value: 9,
        label: "09",
    },
    {
        value: 10,
        label: "10",
    },
];

export const marks5 = [
    {
        value: 0,
        label: "00",
    },
    {
        value: 1,
        label: "01",
    },
    {
        value: 2,
        label: "02",
    },
    {
        value: 3,
        label: "03",
    },
    {
        value: 4,
        label: "04",
    },
    {
        value: 5,
        label: "05",
    },
];
export const toothNumber = [
    {key: 0, selected: false, value: 11, label: 11},
    {key: 1, selected: false, value: 12, label: 12},
    {key: 2, selected: false, value: 13, label: 13},
    {key: 3, selected: false, value: 14, label: 14},
    {key: 4, selected: false, value: 15, label: 15},
    {key: 5, selected: false, value: 16, label: 16},
    {key: 6, selected: false, value: 17, label: 17},
    {key: 7, selected: false, value: 18, label: 18},
    {key: 8, selected: false, value: 21, label: 21},
    {key: 9, selected: false, value: 22, label: 22},
    {key: 10, selected: false, value: 23, label: 23},
    {key: 11, selected: false, value: 24, label: 24},
    {key: 12, selected: false, value: 25, label: 25},
    {key: 13, selected: false, value: 26, label: 26},
    {key: 14, selected: false, value: 27, label: 27},
    {key: 15, selected: false, value: 28, label: 28},
    {key: 16, selected: false, value: 31, label: 31},
    {key: 17, selected: false, value: 32, label: 32},
    {key: 18, selected: false, value: 33, label: 33},
    {key: 19, selected: false, value: 34, label: 34},
    {key: 20, selected: false, value: 35, label: 35},
    {key: 21, selected: false, value: 36, label: 36},
    {key: 22, selected: false, value: 37, label: 37},
    {key: 23, selected: false, value: 38, label: 38},
    {key: 24, selected: false, value: 41, label: 41},
    {key: 25, selected: false, value: 42, label: 42},
    {key: 26, selected: false, value: 43, label: 43},
    {key: 27, selected: false, value: 44, label: 44},
    {key: 28, selected: false, value: 45, label: 45},
    {key: 29, selected: false, value: 46, label: 46},
    {key: 30, selected: false, value: 47, label: 47},
    {key: 31, selected: false, value: 48, label: 48},
];

export const downloadPdfFromUrl = (url: string, name: string) => {
    fetch(url).then((response) => {
        response.blob().then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = name;
            alink.click();
        });
    });
};

export const convertPayloadToString = (data: any, key: string) => {

    const keysToConvert = [] as any;
    findingData?.map((res: any) => {
        res.options.map((opt: any) => {
            if (opt.type === "multi-select") {
                if (!keysToConvert.includes(opt.key)) {
                    keysToConvert.push(opt.key);
                }
            }
            return true;
        });
        return true;
    });
    if (keysToConvert.includes(key)) {
        return data.toString();
    }
    return data;
};

export const convertMultiSelectArrayToString = (findings: any) => {
    const newFindings = findings;
    Object.keys(findings).map((exam: string) => {
        let newExam = newFindings[exam];
        if (findings[exam] !== null) {
            Object.keys(findings[exam])?.map((finding: string) => {
                if (Array.isArray(findings[exam][finding])) {
                    let convertedData: any;
                    if (
                        Array.isArray(
                            convertPayloadToString(findings[exam][finding], finding)
                        )
                    ) {
                        convertedData = convertPayloadToString(
                            findings[exam][finding],
                            finding
                        )?.map((res: any) => {
                            const newFields = {} as any;
                            Object.keys(res).map((field: string) => {
                                if (Array.isArray(res[field])) {
                                    newFields[field] = convertPayloadToString(res[field], field);
                                } else {
                                    // TODO: add condition only for occlusal_trauma_present due to resolve: in the present filed is false then its pass null everytime
                                    if (finding === 'occlusal_trauma_present') {
                                        newFields[field] = res[field] === 'yes' ? true : res[field] === 'no' ? false : res[field];
                                    } else {
                                        newFields[field] = res[field] || null;
                                    }

                                }
                                return true;
                            });
                            return newFields;
                        });
                    } else {
                        convertedData = convertPayloadToString(
                            findings[exam][finding],
                            finding
                        );
                    }
                    newExam[finding] = convertedData;
                }
                return true;
            });
            return true;
        }

    });
    return newFindings;
};


export const convertMultiSelectStringToArray = (examData: any) => {
    const updatedExamData = removeRefFromJson(examData) as any
    if (updatedExamData) {
        Object.keys(updatedExamData)?.map((exams: any) => {
            updatedExamData[exams] && Object.keys(updatedExamData[exams])?.map((findings: any) => {
                if (updatedExamData[exams][findings] && Array.isArray(updatedExamData[exams][findings])) {
                    updatedExamData[exams][findings]?.map((fields: any, idx: number) => {
                        fields && Object.keys(fields)?.map((field: string,) => {
                            if (findingData?.filter((f: any) => f?.key === findings)[0]?.options?.filter((f: any) => f?.key === field)[0]?.type === 'multi-select') {
                                if (updatedExamData[exams] && updatedExamData[exams][findings] && updatedExamData[exams][findings][idx] && typeof updatedExamData[exams][findings][idx][field] === 'string' && updatedExamData[exams][findings][idx][field]) {
                                    updatedExamData[exams][findings][idx][field] = stringToArray(updatedExamData[exams][findings][idx][field] || '')
                                }
                            }
                            return true
                        })

                        return true
                    })
                } else {
                    // TODO : it's for single value: will manage it later
                    // if (findingData?.filter((f: any) => f?.key === findings)[0]?.options?.filter((f: any) => f?.key === findings)[0]?.type === 'multi-select') {
                    //   if (updatedExamData[exams] && updatedExamData[exams][findings] && updatedExamData[exams][findings]) {
                    //     updatedExamData[exams][findings] = stringToArray(updatedExamData[exams][findings] || '')
                    //   }
                    // }
                    // return true
                    // })
                }

                return true
            })

            return true
        })
    }
    return updatedExamData
}

export const removeNullValue = (examData: any) => {
    const modifiedResponse = {...examData};
    Object.keys(modifiedResponse)?.forEach((exam: any) => {
        Object.keys(modifiedResponse[exam])?.forEach((key: any) => {
            const value = modifiedResponse[exam][key];
            // Check if the value is an array
            if (Array.isArray(value)) {
                // Filter out objects with all null values
                const filteredArray = value?.filter((item: any) => {
                    return !Object.values(item)?.every((val: any) => val === null || val === '');
                });
                // Update the array with the filtered results
                modifiedResponse[exam][key] = filteredArray?.length === 0 ? null : filteredArray;
            }
        })
    })
    return modifiedResponse
}

export const comparePayloadResponse = (payload: any, res: any) => {
    if (payload && res) {
        const keys1 = Object.keys(payload);

        for (const key of keys1) {
            const value1 = payload[key];
            const value2 = res[key];

            if (typeof value1 === 'object' && typeof value2 === 'object') {
                if (comparePayloadResponse(value1, value2)) {
                    return true;
                }
            } else if (value1 !== value2) {
                return true;
            }
        }
    }
    if (res === null) {
        return true
    }
    return false;
}

export const toothNumberForFindings = [
    // { key: 0, selected: false, value: "11", label: 11 },
    // { key: 1, selected: false, value: "21", label: 21 },
    // { key: 2, selected: false, value: "31", label: 31 },
    // { key: 3, selected: false, value: "41", label: 41 },

    // { key: 4, selected: false, value: "12", label: 12 },
    // { key: 5, selected: false, value: "22", label: 22 },
    // { key: 6, selected: false, value: "32", label: 32 },
    // { key: 7, selected: false, value: "42", label: 42 },

    // { key: 8, selected: false, value: "13", label: 13 },
    // { key: 9, selected: false, value: "23", label: 23 },
    // { key: 10, selected: false, value: "33", label: 33 },
    // { key: 11, selected: false, value: "43", label: 43 },

    // { key: 12, selected: false, value: "14", label: 14 },
    // { key: 13, selected: false, value: "24", label: 24 },
    // { key: 14, selected: false, value: "34", label: 34 },
    // { key: 15, selected: false, value: "44", label: 44 },

    // { key: 16, selected: false, value: "15", label: 15 },
    // { key: 17, selected: false, value: "25", label: 25 },
    // { key: 18, selected: false, value: "35", label: 35 },
    // { key: 19, selected: false, value: "45", label: 45 },

    // { key: 20, selected: false, value: "16", label: 16 },
    // { key: 21, selected: false, value: "26", label: 26 },
    // { key: 22, selected: false, value: "36", label: 36 },
    // { key: 23, selected: false, value: "46", label: 46 },

    // { key: 24, selected: false, value: "17", label: 17 },
    // { key: 25, selected: false, value: "27", label: 27 },
    // { key: 26, selected: false, value: "37", label: 37 },
    // { key: 27, selected: false, value: "47", label: 47 },

    // { key: 28, selected: false, value: "18", label: 18 },
    // { key: 29, selected: false, value: "28", label: 28 },
    // { key: 30, selected: false, value: "38", label: 38 },
    // { key: 31, selected: false, value: "48", label: 48 },
    {key: 0, selected: false, value: "11", label: 11},
    {key: 1, selected: false, value: "12", label: 12},
    {key: 2, selected: false, value: "13", label: 13},
    {key: 3, selected: false, value: "14", label: 14},
    {key: 4, selected: false, value: "15", label: 15},
    {key: 5, selected: false, value: "16", label: 16},
    {key: 6, selected: false, value: "17", label: 17},
    {key: 7, selected: false, value: "18", label: 18},
    {key: 8, selected: false, value: "21", label: 21},
    {key: 9, selected: false, value: "22", label: 22},
    {key: 10, selected: false, value: "23", label: 23},
    {key: 11, selected: false, value: "24", label: 24},
    {key: 12, selected: false, value: "25", label: 25},
    {key: 13, selected: false, value: "26", label: 26},
    {key: 14, selected: false, value: "27", label: 27},
    {key: 15, selected: false, value: "28", label: 28},
    {key: 16, selected: false, value: "31", label: 31},
    {key: 17, selected: false, value: "32", label: 32},
    {key: 18, selected: false, value: "33", label: 33},
    {key: 19, selected: false, value: "34", label: 34},
    {key: 20, selected: false, value: "35", label: 35},
    {key: 21, selected: false, value: "36", label: 36},
    {key: 22, selected: false, value: "37", label: 37},
    {key: 23, selected: false, value: "38", label: 38},
    {key: 24, selected: false, value: "41", label: 41},
    {key: 25, selected: false, value: "42", label: 42},
    {key: 26, selected: false, value: "43", label: 43},
    {key: 27, selected: false, value: "44", label: 44},
    {key: 28, selected: false, value: "45", label: 45},
    {key: 29, selected: false, value: "46", label: 46},
    {key: 30, selected: false, value: "47", label: 47},
    {key: 31, selected: false, value: "48", label: 48},
];

// TODO: Will set default value in next sprint
export const payloadValidator = (dentistData: any) => {
    return {
        ...dentistData,
        firstName: dentistData?.firstName ? dentistData?.firstName : "",
        lastName: dentistData?.lastName ? dentistData?.lastName : "",
        name: dentistData.firstName + " " + dentistData.lastName,
        sodium_flouride_mouthrinse_daily:
            dentistData?.sodium_flouride_mouthrinse_daily || false,
        drymouth: dentistData?.drymouth || false,
        chlorhexidine_gluconate_mouthrinse_daily:
            dentistData?.chlorhexidine_gluconate_mouthrinse_daily || false,
        flouride_toothpaste_varnish_last_6_month:
            dentistData?.flouride_toothpaste_varnish_last_6_month || false,
        flouride_toothpaste_5000_ppm:
            dentistData?.flouride_toothpaste_5000_ppm || false,
        flouride_toothpaste_2x_or_more:
            dentistData?.flouride_toothpaste_2x_or_more || false,
        flouride_toothpaste_once_a_day:
            dentistData?.flouride_toothpaste_once_a_day || false,
        flouride_water: dentistData?.flouride_water || false,
        relationship_to_emergency_contact:
            dentistData?.relationship_to_emergency_contact
                ? dentistData?.relationship_to_emergency_contact?.trim()
                : "",
        last_see_dental_hygienist: dentistData?.last_see_dental_hygienist
            ? dateFormat(dentistData?.last_see_dental_hygienist)
            : "",
        date_of_last_dental_x_ray: dentistData?.date_of_last_dental_x_ray
            ? dateFormat(dentistData?.date_of_last_dental_x_ray)
            : "",
        last_visit_to_physician: dentistData?.last_visit_to_physician
            ? dateFormat(dentistData?.last_visit_to_physician)
            : "",
        date_of_birth: dentistData?.date_of_birth
            ? dateFormat(dentistData?.date_of_birth)
            : "",
        mobile_phone_number: dentistData?.mobile_phone_number
            ? formatPhoneNumber(dentistData?.mobile_phone_number)
            : "",
        emergency_contact_phone_number: dentistData?.emergency_contact_phone_number
            ? formatPhoneNumber(dentistData?.emergency_contact_phone_number)
            : "",
        list_of_checked_items: dentistData?.list_of_checked_items
            ? dentistData?.list_of_checked_items?.toString()
            : "",
        allergies_list: dentistData?.allergies_list
            ? dentistData?.allergies_list?.toString()
            : "",
        dental_info: dentistData?.dental_info
            ? dentistData?.dental_info?.toString()
            : "",
        height_unit: dentistData?.height_unit ? dentistData?.height_unit : "ft",
        weight_unit: dentistData?.weight_unit ? dentistData?.weight_unit : "kg",
        address: dentistData?.address ? dentistData?.address?.trim() : "",
        city: dentistData?.city ? dentistData?.city?.trim() : "",
        postal_code: dentistData?.postal_code ? dentistData?.postal_code : "",
        home_phone_number: dentistData?.home_phone_number
            ? formatPhoneNumber(dentistData?.home_phone_number)
            : "",
        email: dentistData?.email ? dentistData?.email : "",
        emergency_contact: dentistData?.emergency_contact
            ? dentistData?.emergency_contact?.trim()
            : "",
        occupation: dentistData?.occupation ? dentistData?.occupation?.trim() : "",
        does_your_work_involve_night_shift:
            dentistData?.does_your_work_involve_night_shift
                ? dentistData?.does_your_work_involve_night_shift
                : false,
        referred_by: dentistData?.referred_by
            ? dentistData?.referred_by?.trim()
            : "",
        marital_status: dentistData?.marital_status
            ? dentistData?.marital_status
            : "",
        gender: dentistData?.gender ? dentistData?.gender : "",
        dental_insurance: dentistData?.dental_insurance
            ? dentistData?.dental_insurance
            : false,
        insurance_provider: dentistData?.insurance_provider
            ? dentistData?.insurance_provider?.trim()
            : "",
        insurance_group_number: dentistData?.insurance_group_number
            ? dentistData?.insurance_group_number?.trim()
            : "",
        insurance_id: dentistData?.insurance_id
            ? dentistData?.insurance_id?.trim()
            : "",
        history_of_smoking: dentistData?.history_of_smoking
            ? dentistData?.history_of_smoking?.trim()
            : "",
        alcohol_usage_in_week: dentistData?.alcohol_usage_in_week
            ? dentistData?.alcohol_usage_in_week?.trim()
            : "",
        recreational_drug_use: dentistData?.recreational_drug_use
            ? dentistData?.recreational_drug_use?.trim()
            : "None",
        recreational_drug_use_other: dentistData?.recreational_drug_use_other
            ? dentistData?.recreational_drug_use_other?.trim()
            : "",
        height: dentistData?.height ? dentistData?.height : "",
        weight: dentistData?.weight ? dentistData?.weight : "",
        dental_anxiety: dentistData?.dental_anxiety
            ? dentistData?.dental_anxiety
            : 0,
        reason_for_scheduling_this_visit:
            dentistData?.reason_for_scheduling_this_visit
                ? dentistData?.reason_for_scheduling_this_visit?.trim()
                : "",
        dentist_view: dentistData?.dentist_view ? dentistData?.dentist_view : null,
        previous_dental_office_name: dentistData?.previous_dental_office_name
            ? dentistData?.previous_dental_office_name?.trim()
            : "",
        antibiotic_premedication_require_prior_dental_appointments:
            dentistData?.antibiotic_premedication_require_prior_dental_appointments
                ? dentistData?.antibiotic_premedication_require_prior_dental_appointments
                : null,
        antibiotic_premedication: dentistData?.antibiotic_premedication
            ? dentistData?.antibiotic_premedication?.trim()
            : "",
        dental_health_average: dentistData?.dental_health_average
            ? dentistData?.dental_health_average
            : "",
        appearance_smile_scale_to_10: dentistData?.appearance_smile_scale_to_10
            ? dentistData?.appearance_smile_scale_to_10
            : 0,
        wish_correct_appearance_smile: dentistData?.wish_correct_appearance_smile
            ? dentistData?.wish_correct_appearance_smile
            : false,
        wish_whiter_smile: dentistData?.wish_whiter_smile
            ? dentistData?.wish_whiter_smile
            : false,
        brand_toothpaste: dentistData?.brand_toothpaste
            ? dentistData?.brand_toothpaste?.trim()
            : "",
        diagnosed_sleep_apnea_ever: dentistData?.diagnosed_sleep_apnea_ever
            ? dentistData?.diagnosed_sleep_apnea_ever
            : false,
        neck_circumference: dentistData?.neck_circumference
            ? dentistData?.neck_circumference?.trim()
            : "",
        loud_snore: dentistData?.loud_snore ? dentistData?.loud_snore?.trim() : "",
        fatigue_daily_bases: dentistData?.fatigue_daily_bases
            ? dentistData?.fatigue_daily_bases
            : false,
        breathing_stop_sleep: dentistData?.breathing_stop_sleep
            ? dentistData?.breathing_stop_sleep
            : false,
        difficulty_breathing_through_your_nose:
            dentistData?.difficulty_breathing_through_your_nose
                ? dentistData?.difficulty_breathing_through_your_nose
                : false,
        cpap_machine: dentistData?.cpap_machine ? dentistData?.cpap_machine : false,
        oral_appliance_sleep: dentistData?.oral_appliance_sleep
            ? dentistData?.oral_appliance_sleep
            : false,
        sitting_reading_epworth_scale: dentistData?.sitting_reading_epworth_scale
            ? dentistData?.sitting_reading_epworth_scale?.trim()
            : "",
        watching_tv_epworth_scale: dentistData?.watching_tv_epworth_scale
            ? dentistData?.watching_tv_epworth_scale?.trim()
            : "",
        sitting_inactive_epworth_scale: dentistData?.sitting_inactive_epworth_scale
            ? dentistData?.sitting_inactive_epworth_scale?.trim()
            : "",
        being_passenger_car_epworth_scale:
            dentistData?.being_passenger_car_epworth_scale
                ? dentistData?.being_passenger_car_epworth_scale?.trim()
                : "",
        lying_down_afternoon_epworth_scale:
            dentistData?.lying_down_afternoon_epworth_scale
                ? dentistData?.lying_down_afternoon_epworth_scale?.trim()
                : "",
        sitting_talking_someone_epworth_scale:
            dentistData?.sitting_talking_someone_epworth_scale
                ? dentistData?.sitting_talking_someone_epworth_scale?.trim()
                : "",
        sitting_quietly_lunch_epworth_scale:
            dentistData?.sitting_quietly_lunch_epworth_scale
                ? dentistData?.sitting_quietly_lunch_epworth_scale?.trim()
                : "",
        stopping_fewminutes_driving_epworth_scale:
            dentistData?.stopping_fewminutes_driving_epworth_scale
                ? dentistData?.stopping_fewminutes_driving_epworth_scale?.trim()
                : "",
        physicians_name: dentistData?.physicians_name
            ? dentistData?.physicians_name?.trim()
            : "",
        physician_phone_number: dentistData?.physician_phone_number
            ? formatPhoneNumber(dentistData?.physician_phone_number)
            : "",
        serious_illness_or_operation_existance:
            dentistData?.serious_illness_or_operation_existance
                ? dentistData?.serious_illness_or_operation_existance
                : false,
        serious_illness_or_operation_name:
            dentistData?.serious_illness_or_operation_name
                ? dentistData?.serious_illness_or_operation_name?.trim()
                : "",
        medication_list: dentistData?.medication_list
            ? dentistData?.medication_list?.trim()
            : "",
        birth_control: dentistData?.birth_control
            ? dentistData?.birth_control
            : false,
        pregnant: dentistData?.pregnant ? dentistData?.pregnant : false,
        nursing: dentistData?.nursing ? dentistData?.nursing : false,
        chest_pain_shortness_breath: dentistData?.chest_pain_shortness_breath
            ? dentistData?.chest_pain_shortness_breath
            : false,
        importancy_of_teeth_health_scale:
            dentistData?.importancy_of_teeth_health_scale
                ? dentistData?.importancy_of_teeth_health_scale
                : 0,
        loose_tooth_feeling_scale: dentistData?.loose_tooth_feeling_scale
            ? dentistData?.loose_tooth_feeling_scale
            : 0,
        chew_importance_scale: dentistData?.chew_importance_scale
            ? dentistData?.chew_importance_scale
            : 0,
        appearance_teeth_scale: dentistData?.appearance_teeth_scale
            ? dentistData?.appearance_teeth_scale
            : 0,
        concerns_appearance_teeth_existance:
            dentistData?.concerns_appearance_teeth_existance
                ? dentistData?.concerns_appearance_teeth_existance
                : null,
        concerns_appearance_teeth_existance_explain:
            dentistData?.concerns_appearance_teeth_existance_explain
                ? dentistData?.concerns_appearance_teeth_existance_explain.trim()
                : "",
        teeth_sensitivity: dentistData?.teeth_sensitivity
            ? dentistData?.teeth_sensitivity
            : null,
        teeth_sensitivity_answer_yes: dentistData?.teeth_sensitivity_answer_yes
            ? dentistData?.teeth_sensitivity_answer_yes?.trim()
            : "",
        describe_yourself_1: dentistData?.describe_yourself_1
            ? dentistData?.describe_yourself_1?.trim()
            : "",
        describe_yourself_2: dentistData?.describe_yourself_2
            ? dentistData?.describe_yourself_2?.trim()
            : "",
        patient_id: dentistData?.patient_id ? dentistData?.patient_id : "",
        patient_initials: dentistData?.patient_initials
            ? dentistData?.patient_initials
            : "",
        snack_3x_day: dentistData?.snack_3x_day ? dentistData?.snack_3x_day : null,
        filling_had_in_last_3_year: dentistData?.filling_had_in_last_3_year
            ? dentistData?.filling_had_in_last_3_year?.trim()
            : "",
        tooth_extracted_last_3_year: dentistData?.tooth_extracted_last_3_year
            ? dentistData?.tooth_extracted_last_3_year
            : null,
        family_history_of_cavities_toothloss:
            dentistData?.family_history_of_cavities_toothloss
                ? dentistData?.family_history_of_cavities_toothloss
                : null,
    };
};

export const allowNumberOnly = (event: any) => {
    let charCode = event.target.keyCode || event?.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        event.preventDefault();
        // event.target.value = event.target.value.replace(/[^0-9.-]/g, "");
    } else {
        event.target.value = event.target.value.replace(/[^0-9. ]/g, "");
    }
};

export const toothSurface = [
    {key: 0, label: "Mesial", value: "Mesial", selected: false},
    {key: 1, label: "Occlusal", value: "Occlusal", selected: false},
    {key: 2, label: "Buccal", value: "Buccal", selected: false},
    {key: 3, label: "Distal", value: "Distal", selected: false},
    {key: 4, label: "Lingual", value: "Lingual", selected: false},
    {key: 5, label: "Incisal", value: "Incisal", selected: false},
];

export const jsonParseAndStringfy = (data: any) => {
    return JSON.parse(JSON.stringify(data));
};

export const errorToaster = (message: string) => {
    toast.error(message || "Something went wrong", {
        className: "toaster-container-error",
        icon: false,
    });
};
export const successToaster = (message: string) => {
    toast.success(message, {
        className: "toaster-container",
        icon: false,
    });
};

/*To check the object is empty or not [returnType: boolean]*/
export const isEmptyObject = (obj: object) => Object.keys(obj).length < 1;

//logout

export const handleLogout = () => {
    localStorage.removeItem("clinicInfo");
    localStorage.removeItem("clinic");
    localStorage.removeItem("dentistToken");
    localStorage.removeItem("dentistUserData");
    window.location.href = "/v2/dentist/login";
};

export const handlePatientLogout = () => {
    localStorage.removeItem("patientToken");
    localStorage.removeItem("patientUserData");
    localStorage.removeItem("social_login");
    localStorage.removeItem('patientClinicInfo')
    localStorage.removeItem('patientClinic')
    window.location.href = '/patient/login'
};

export const examList = {
    IO: {
        title: "IO",
        key: "intra_oral",
        name: "Intra Oral Exam",
        color: theme.palette.tagColor.green,
        isXray: true,
        isImage: true,
    },
    EO: {
        title: "EO",
        key: "extra_oral",
        name: "Extra Oral Exam",
        color: theme.palette.primary.main,
        isXray: false,
        isImage: true,
    },
    OE: {
        title: "OE",
        key: "occlusion_exam",
        name: "Occlusion Exam",
        color: theme.palette.secondary.main,
        isXray: true,
        isImage: true,
    },
    PR: {
        title: "PR",
        key: "periodontal_exam",
        text: "Periodontal Risk",
        color: theme.palette.grey["A400"],
        isXray: true,
        isImage: true,
    },
    WE: {
        title: "WE",
        key: "wear_exam",
        name: "Wear Exam",
        color: theme.palette.warning.main,
        isXray: false,
        isImage: true,
    },
    TE: {
        title: "TE",
        key: "tmj_exam",
        name: "TMJ & Muscle exam",
        color: theme.palette.tagColor.lightBlue,
        isXray: false,
        isImage: false,
    },
    EE: {
        title: "EE",
        key: "existing_treatment",
        name: "Existing Exam",
        color: theme.palette.tagColor.purple,
        isXray: true,
        isImage: true,
    },
    PE: {
        title: "PE",
        key: "pathology_treatment",
        name: "Pathology Exam",
        color: "#FD9308",
        isXray: true,
        isImage: true,
    },
} as any;
export const tagList = [
    {
        title: "IO",
        key: "intra_oral",
    },
    {
        title: "EO",
        key: "extra_oral",
    },
    {
        title: "OE",
        key: "occlusion_exam",
    }, {
        title: "PR",
        key: "periodontal_exam",
    }, {
        title: "WE",
        key: "wear_exam",
    }, {
        title: "TE",
        key: "tmj_exam",
    }, {
        title: "EE",
        key: "existing_treatment",
    }, {
        title: "PE",
        key: "pathology_treatment",
    }]
export const convertValueToRoman = (val: string) => {
    return val === '1' ? 'I' : val === '2' ? 'II' : val === '3' ? 'III' : ''
}
export const handleChangeWithDebounce = (fn: any) => debounce(fn, 500);

export const getToothIndex = (tooth: number, lingual?: boolean) => {
    switch (tooth) {
        case 18:
            return lingual ? 16 : 1
        case 17:
            return lingual ? 15 : 2
        case 16:
            return lingual ? 14 : 3
        case 15:
            return lingual ? 13 : 4
        case 14:
            return lingual ? 12 : 5
        case 13:
            return lingual ? 11 : 6
        case 12:
            return lingual ? 10 : 7
        case 11:
            return lingual ? 9 : 8
        case 21:
            return lingual ? 8 : 9
        case 22:
            return lingual ? 7 : 10
        case 23:
            return lingual ? 6 : 11
        case 24:
            return lingual ? 5 : 12
        case 25:
            return lingual ? 4 : 13
        case 26:
            return lingual ? 3 : 14
        case 27:
            return lingual ? 2 : 15
        case 28:
            return lingual ? 1 : 16
        case 38:
            return lingual ? 32 : 17
        case 37:
            return lingual ? 31 : 18
        case 36:
            return lingual ? 30 : 19
        case 35:
            return lingual ? 29 : 20
        case 34:
            return lingual ? 28 : 21
        case 33:
            return lingual ? 27 : 22
        case 32:
            return lingual ? 26 : 23
        case 31:
            return lingual ? 25 : 24
        case 41:
            return lingual ? 24 : 25
        case 42:
            return lingual ? 23 : 26
        case 43:
            return lingual ? 22 : 27
        case 44:
            return lingual ? 21 : 28
        case 45:
            return lingual ? 20 : 29
        case 46:
            return lingual ? 19 : 30
        case 47:
            return lingual ? 18 : 31
        case 48:
            return lingual ? 17 : 32
        default:
            return 0
    }
}

interface ToothNumbers {
    [key: number]: number;
}

export const toothNumberPreferences = (tooth: number, isPatient?: boolean) => {
    const localStorageData = getLocalStorageItem(isPatient ? "patientClinicInfo" : "clinicInfo") as any;
    const ClinicInfo = JSON.parse(localStorageData)
    const toothNumbers = {
        18: 1,
        17: 2,
        16: 3,
        15: 4,
        14: 5,
        13: 6,
        12: 7,
        11: 8,
        21: 9,
        22: 10,
        23: 11,
        24: 12,
        25: 13,
        26: 14,
        27: 15,
        28: 16,
        38: 17,
        37: 18,
        36: 19,
        35: 20,
        34: 21,
        33: 22,
        32: 23,
        31: 24,
        41: 25,
        42: 26,
        43: 27,
        44: 28,
        45: 29,
        46: 30,
        47: 31,
        48: 32,
    } as ToothNumbers
    return ClinicInfo?.tooth_numbering_format === "CAN" ? tooth : toothNumbers[tooth]
}

export const treatmentDescription = (treatmentFinding: any, fromAlternative: boolean, isPatient?: boolean) => {
    if (treatmentFinding) {
        const {tooth_number, tooth_surface, subtype, finding} = treatmentFinding;
        const newToothNumber = toothNumberPreferences(parseInt(tooth_number), isPatient)
        const updatedToothSurface = fromAlternative
            ? (stringToArray(tooth_surface)?.map((surface: string) => getShortSurface(surface))?.join(''))
            : tooth_surface;
        if (newToothNumber && tooth_surface && subtype) {
            return `${newToothNumber || ''}-${finding?.replace(/_/g, ' ')}-${updatedToothSurface?.replace(/,/g, '')}-${subtype}`;
        } else if (newToothNumber && tooth_surface) {
            return `${newToothNumber || ''}-${finding?.replace(/_/g, ' ')}-${updatedToothSurface?.replace(/,/g, '')}`;
        } else if (newToothNumber && subtype) {
            return `${newToothNumber || ''}-${finding?.replace(/_/g, ' ')}-${subtype}`;
        } else if (tooth_surface && subtype) {
            return `${finding?.replace(/_/g, ' ')}-${updatedToothSurface?.replace(/,/g, '')}-${subtype}`;
        } else if (tooth_surface) {
            return `${finding?.replace(/_/g, ' ')}-${updatedToothSurface?.replace(/,/g, '')}`;
        } else if (subtype) {
            return `${finding?.replace(/_/g, ' ')}-${subtype}`;
        } else if (newToothNumber) {
            return `${newToothNumber || ''}-${finding?.replace(/_/g, ' ')}`;
        } else {
            return finding
        }
    }
    return '';
};

export const spinLoader = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const checkIsEmptyValidator = (value: any) => {
    if (value === '') {
        return validator.isEmpty(value)
    } else {
        return value === null || value === ''
    }

}

export const alphabetRegex = /[a-zA-Z]/;

export const calculateTotalFees = (item: any) => {
    let totalFees;
    if (item?.fees?.includes("-") || alphabetRegex.test(item?.fees)) {
        totalFees = item?.fees
    } else {
        const fee = parseFloat(item?.fees)?.toFixed(2) as any
        totalFees = ((fee) * (item?.quantity || 1))?.toFixed(2) as any
    }
    return totalFees;
};

export const hideMiddleDigits = (phoneNumber: string) => {
    const cleaned = phoneNumber?.replace(/\D/g, ''); // Remove all non-numeric characters
    const firstTwo = cleaned?.slice(0, 2);
    const lastTwo = cleaned?.slice(-2);
    const maskedSection = cleaned?.slice(2, -2)?.replace(/./g, '*');
    return `${firstTwo}${maskedSection}${lastTwo}`;
}

export const exceptThisSymbols = ['e', 'E', '+', '-', '.']
export const callAPIForSecondScreenUpdate = (appointmentId: any, isSecondScreenCondition: any, apRequest: any) => {
    // Subscribe to the Pusher channel
    const channel = pusher.subscribe('second_screen_channel');
    // Define the handler for the event
    const handleEvent = (data: any) => {
        if (data?.data_changed && isSecondScreenCondition(data) && !data?.is_annotation) {
            apRequest();
        }
    };
    // Bind the handler to the event
    channel.bind(appointmentId || '', handleEvent);
    // Return a cleanup function
    return () => {
        channel.unbind(appointmentId || '', handleEvent);
        pusher.unsubscribe('second_screen_channel');
    };
};

export const accessCodeDisplay = (code: string) => {
    if (code.length !== 10) return code; // Ensure the code has exactly 10 digits
    return `${code.slice(0, 3)}-${code.slice(3, 6)}-${code.slice(6)}`;
};

export const changeAnnotationResponse = (annotationData: any) => {
    const keyToReplace = [
        "cause",
        "tooth_number",
        "subtype",
        "tooth_site",
        "tooth_surface",
        "grade",
        "present",
        "mm",
    ] as any;
    const keysToKeep = [
        'recession',
        "furcation",
        'decay',
        'filling',
        'watch',
        'implant',
        'impacted_tooth',
        'crown',
        'sct',
        'inlay',
        'onlay',
        'veneer',
        'pontic',
        'abutment',
        'denture',
        'open_contact',
        'mobility',
        'localized_bone_loss',
        'non_carious_cervical_lesions',
        'minimal_attached_tissue'
    ]
    const updatedDetails = [] as any;
    const isMultiSelect = (finding: any, key: string) => {
        const currentFinding = findingData.filter(
            (f: any) => finding?.findings === f.key
        ) as any;
        return (
            currentFinding[0]?.options.filter((f: any) => f.key === key)[0] &&
            currentFinding[0]?.options.filter((f: any) => f.key === key)[0]
                ?.type === "multi-select"
        );
    };
    const transformedDetails = annotationData.map((item: any) => {
        const {finding_details, ...rest} = item;
        return {
            ...rest,
            ...finding_details
        };
    });
    const processData = () => {
        return transformedDetails.map((item: any) => {
            const ncclTypes = [];

            // Check for the conditions and populate ncclTypes accordingly
            if (item.erosion) {
                ncclTypes.push("Erosion");
            }
            if (item.abrasion) {
                ncclTypes.push("Abrasion");
            }
            if (item.attrition) {
                ncclTypes.push("Attrition");
            }
            if (item.is_dentin_exposed) {
                ncclTypes.push("Is Dentin Exposed");
            }
            if (item.abfraction) {
                ncclTypes.push("Abfraction");
            }
            delete item.erosion
            delete item.abrasion
            delete item.attrition
            delete item.is_dentin_exposed
            delete item.abfraction
            // Return the item with the added ncclTypes field
            return {
                ...item,
                ncclTypes
            };
        });
    };
    processData()?.map((res: any) => {
        const newObj = {} as any;
        Object.keys(res).map((k) => {
            if (keyToReplace.includes(k) && !isMultiSelect(res, k) && !keysToKeep?.includes(res?.finding)) {
                newObj[`${res.finding}_${k}`] =
                    typeof res[k] === "boolean" ? (res[k] ? "yes" : "no") : res[k];
            } else {
                newObj[k] =
                    typeof res[k] === "boolean" ? (res[k] ? "yes" : "no") : res[k];
            }
        });
        updatedDetails.push(newObj);
        return true;
    });
    return updatedDetails;
}