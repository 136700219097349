import React from 'react'
import {
    Box, Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo, faNoteSticky} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import theme from '../../../../../../theme';
import {RootState} from "../../../../../../app/reducers/v2/rootReducer";
import CollapsibleBox from "../../../../common/CollapsibleBox";
import { treatmentDescription} from "../../../../../../utils/helper";
import ALIcon from "../../../../../../assets/images/v2/alternativeIcon.svg";
import NoDataFound from "../../../../../v1/Common/NoDataFound";
import {plannedTreatmentColumns} from "../../../../../../utils/v2/wellnessProfileConstant";
import TextBox from "../../../../common/TextBox";

const useStyles = makeStyles((theme: any) => ({
    table: {
        borderCollapse: "separate",
        boxShadow: "none",
        "& .MuiGrid-root": {
            backgroundColor: theme.palette.background.secondaryPaper,
        },
    },
    thead: {
        backgroundColor: theme.palette.primary.greenLight,
        color: theme.palette.common.black50,
        height: '50px',
        border: '1px solid red'
    },
    th: {
        padding: "13px 16px",
        fontSize: "13px",
        lineHeight: "150%",
        color: theme.palette.common.black50,
        overflowX: "auto",
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${theme.palette.v2.primary.main}`
    },
    tr: {
        background: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    td: {
        height: `40px !important`,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    FontAwesomeIcon: {
        marginRight: '8px', color: theme.palette.common.black50
    },
    noteClass: {
        marginLeft: '8px !important',
        width: '15px',
        height: '15px'
    },
    trBg: {
        background: theme.palette.background.secondaryPaper,
    },
}));

const WellnessPlannedTreatments = () => {
    const classes = useStyles();
    const {palette} = theme
    const {wellnessProfileFilters: {wellnessProfileData},} = useSelector((state: RootState) => state);

    return (
        <React.Fragment>
            {wellnessProfileData?.treatements?.length > 0 ? wellnessProfileData?.treatements?.map((res: any, mainIndex: number) =>
                <CollapsibleBox title={`Appointment ${mainIndex + 1}`} key={mainIndex} id={`${mainIndex}`}>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label='simple table' classes={{root: classes.table}}>
                            <TableHead classes={{root: classes.thead}}>
                                <TableRow>
                                    {plannedTreatmentColumns?.map((column: any, i: number) => <TableCell
                                        key={i}
                                        className="fw-regular"
                                        classes={{root: column.label === 'Action' ? classes.th : classes.th}}
                                        align={column.label === 'Action' ? 'center' : 'left'}
                                        sx={{
                                            minWidth: column.minWidth,
                                            maxWidth: column.maxWidth,
                                            width: column.width,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                borderLeft: `${i === 0 && 'none !important'}`,
                                                paddingLeft: `${i === 0 && '0px !important'}`
                                            }}>
                                            {column.label}
                                        </Box>
                                    </TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{backgroundColor: palette.common.white}}>
                                <React.Fragment>
                                    {res?.treatments?.sort((a: any, b: any) => a?.inner_position - b?.inner_position)?.map((row: any, index: number) =>
                                        <React.Fragment>
                                            <TableRow key={index}
                                                      classes={{root: row?.treatment_status === "rejected" ? classes.trBg : classes.tr}}>
                                                <TableCell sx={{minWidth: 100, maxWidth: 150,}}
                                                           classes={{root: classes.td}}
                                                           className='f-14 fw-regular f-w-400 labelColor' align='left'>
                                                    <Box className={'d-flex'}>
                                                        <Typography
                                                            className='fw-regular f-w-400'>{index + 1}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    sx={{minWidth: 600, maxWidth: 850, width: '100%'}}
                                                    classes={{root: classes.td}} align='left'>
                                                    <Box>
                                                        {row?.treatment_finding ?
                                                            <Typography textTransform={'capitalize'} className='f-14 fw-medium f-w-500 labelColor'
                                                                        sx={{textTransform: 'capitalize'}}>
                                                                {treatmentDescription(row?.treatment_finding, true)}
                                                            </Typography>
                                                            : null}
                                                        <Box className='d-flex'>
                                                            {
                                                                row?.treatment_type === 'other' ?
                                                                    <Typography textTransform={'capitalize'}
                                                                        className={`f-14 fw-regular f-w-400 labelColor`}>
                                                                        {row?.procedure_text}
                                                                    </Typography>
                                                                    : row?.treatment_type === 'additional' ?
                                                                        (<Box className='d-flex'>
                                                                            <Typography textTransform={'capitalize'}
                                                                                className={`${row?.treatment_finding ? 'f-12 ' : 'f-14'} fw-regular f-w-400 labelColor`}>
                                                                                {row?.treatment_procedure[0]?.description}
                                                                                {row?.treatment_procedure[0]?.notes &&
                                                                                    <Tooltip
                                                                                        title={row?.treatment_procedure[0]?.notes}
                                                                                        arrow
                                                                                        classes={{tooltip: 'fw-regular f-14 lh-18'}}>
                                                                                        <FontAwesomeIcon icon={faNoteSticky}
                                                                                                         color={palette.v2.primary.main}
                                                                                                         className={`${classes.noteClass} cursor-pointer f-14`}
                                                                                        />
                                                                                    </Tooltip>
                                                                                }
                                                                            </Typography>
                                                                        </Box>)
                                                                        : row?.treatment_procedure?.map((res: any, i: number) => (
                                                                            <Box className='d-flex' key={i}>
                                                                                <Typography key={i} textTransform={'capitalize'}
                                                                                            className={`${row?.treatment_finding ? 'f-12 ' : 'f-14'} fw-regular f-w-400 labelColor`}>
                                                                                    {res?.quantity === null && res?.description}
                                                                                </Typography>
                                                                            </Box>
                                                                        ))
                                                            }
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{minWidth: 100, maxWidth: 150,}}
                                                           classes={{root: classes.td}}
                                                           className='f-14 fw-regular f-w-400 labelColor' align='left'>
                                                    <Box className={'d-flex'}>
                                                        <Typography
                                                            className='fw-regular f-w-400'>{row?.created_at || '-'}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{minWidth: 124,}} classes={{root: classes.td}}
                                                           className='f-14 fw-regular f-w-400 labelColor' align='left'>
                                                    <Box className='d-flex flex-col'>
                                                        {row?.treatment_type === 'other' ? "-" :
                                                            row?.treatment_type === 'additional' ?
                                                                <Box className='d-flex align-center'>
                                                                    <Typography
                                                                        className='f-14 fw-regular f-w-400 labelColor '>
                                                                        {row?.treatment_procedure[0]?.code}
                                                                    </Typography>
                                                                    {row?.treatment_procedure[0]?.quantity > 1 ?
                                                                        <Typography
                                                                            className={`${row?.treatment_finding ? 'f-12 ' : 'f-14'} fw-regular f-w-400 labelColor`}>
                                                                            {` x(${row?.treatment_procedure[0]?.quantity})`}
                                                                        </Typography> : null}
                                                                </Box>
                                                                :
                                                                row?.treatment_procedure?.map((res: any, i: number) => (
                                                                    <Box className='d-flex align-center' key={i}>
                                                                        <Typography key={i}
                                                                                    className='f-14 fw-regular f-w-400 labelColor '>
                                                                            {res?.code}
                                                                        </Typography>
                                                                        {res?.quantity ?
                                                                            <Typography textTransform={'capitalize'}
                                                                                className={`${row?.treatment_finding ? 'f-12 ' : 'f-14'} fw-regular f-w-400 labelColor`}>
                                                                                {` x(${res?.quantity})`}
                                                                            </Typography> : null}
                                                                        {(row?.treatment_procedure?.length - 1 === i) ? '' : ','}&nbsp;
                                                                    </Box>
                                                                ))}
                                                    </Box>
                                                </TableCell>

                                                {/*TODO: IF WE SHOW FEES*/}
                                                {/*<TableCell sx={{minWidth: 124,}} classes={{root: classes.td}}*/}
                                                {/*           className='f-14 fw-regular f-w-400 labelColor' align='left'>*/}
                                                {/*    <Typography*/}
                                                {/*        className='f-14 fw-regular f-w-400 labelColor d-flex flex-col'>*/}
                                                {/*        {row?.treatment_procedure?.map((item: any) =>*/}
                                                {/*            <Typography className='f-14 fw-regular f-w-400 labelColor'>*/}
                                                {/*                {!alphabetRegex.test(item?.fees) && '$'}*/}
                                                {/*                {calculateTotalFees(item)}*/}
                                                {/*            </Typography>*/}
                                                {/*        )}*/}
                                                {/*    </Typography>*/}
                                                {/*</TableCell>*/}
                                            </TableRow>
                                            {(row?.alternative_procedure || row?.alternative_treatment_type === 'other') ?
                                                <TableRow>
                                                    <TableCell classes={{root: classes.td}} align='left'/>
                                                    <TableCell classes={{root: classes.td}} align='left'>
                                                        <Box className='d-flex'>
                                                            <img src={ALIcon} alt='alternative'/>
                                                            <Box ml={3} mt={'2px'}>
                                                                {row?.alternative_finding ?
                                                                    <Typography
                                                                        className='f-14 fw-medium f-w-500 labelColor'
                                                                        sx={{textTransform: 'capitalize'}}>
                                                                        {treatmentDescription(row?.alternative_finding, true)}
                                                                    </Typography>
                                                                    : null}
                                                                {row?.alternative_treatment_type === 'other' ?
                                                                    <Typography
                                                                        className={`f-14 fw-regular f-w-400  labelColor`}>
                                                                        {row?.alternative_procedure_text}
                                                                    </Typography>
                                                                    : <Typography
                                                                        className={`${row?.alternative_finding ? 'f-12' : 'f-14 '} fw-regular f-w-400  labelColor`}>
                                                                        {row?.alternative_procedure?.description}
                                                                    </Typography>}
                                                                <Box className='d-flex'>
                                                                    <FontAwesomeIcon icon={faInfo}
                                                                                     color={palette.error.main}
                                                                                     className='f-14 fw-regular f-w-400'/>
                                                                    <Typography
                                                                        ml={1}
                                                                        className='f-10 fw-regular f-w-400'
                                                                        color='error'>
                                                                        Patient declines optimal treatment
                                                                        recommendation and opts for
                                                                        alternative</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell sx={{minWidth: 100, maxWidth: 150,}}
                                                               classes={{root: classes.td}}
                                                               className='f-14 fw-regular f-w-400 labelColor'
                                                               align='left'>
                                                        <Box className={'d-flex'}>
                                                            <Typography
                                                                className='fw-regular f-w-400'>{'-'}</Typography>
                                                        </Box>
                                                    </TableCell>

                                                    <TableCell classes={{root: classes.td}}
                                                               className='f-14 fw-regular f-w-400 labelColor'
                                                               align='left'>
                                                        {row?.alternative_treatment_type === 'other' ? "-" : row?.alternative_procedure?.code}
                                                    </TableCell>

                                                    {/*TODO: IF WE SHOW FEES*/}
                                                    {/*<TableCell classes={{root: classes.td}}*/}
                                                    {/*           className='f-14 fw-regular f-w-400 labelColor'*/}
                                                    {/*           align='left'>*/}
                                                    {/*    {!alphabetRegex.test(row?.alternative_procedure?.fees) && '$'}*/}
                                                    {/*    {row?.alternative_procedure?.fees}*/}
                                                    {/*</TableCell>*/}
                                                </TableRow>
                                                : null}
                                        </React.Fragment>
                                    )}

                                </React.Fragment>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextBox
                                label={'Notes'}
                                labelProps={{
                                    htmlFor: 'notes'
                                }}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled
                                inputProps={{
                                    id: 'notes',
                                    name: 'notes',
                                    value: res?.notes || '',
                                    fullWidth: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </CollapsibleBox>
            ) : <Box py={2}>
                <NoDataFound errorMessage={'planned treatment'}/>
            </Box>}
        </React.Fragment>

    )
}

export default WellnessPlannedTreatments